
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'pharmacies',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/pharmacies/pharmacies.module').then(m => m.PharmaciesModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'book-appointment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/book-appointment/book-appointment.module').then(m => m.BookAppointmentModule)
  },
  {
    path: 'store-locator',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/store-locator/store-locator.module').then(m => m.StoreLocatorModule)
  },
  {
    path: 'screening',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/screening/screening.module').then(m => m.ScreeningModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
