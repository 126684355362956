
<div class="errors-cnt"
     *ngIf="elementControl
      && (elementControl?.dirty || elementControl?.touched)
      && elementControl?.invalid">

  <div *ngIf="elementControl.errors?.required; else minLength">
    {{'errors.field_required' | translate: {fieldName: fieldLabel} }}
  </div>

  <ng-template #minLength>
    <div *ngIf="elementControl.errors?.minlength; else maxLength">
      {{'errors.min_length_error'
        | translate: {
            fieldName: fieldLabel,
            value: elementControl.errors?.minlength?.requiredLength
      } }}
    </div>
  </ng-template>

  <ng-template #maxLength>
    <div *ngIf="elementControl.errors?.maxlength; else maxNumber">
      {{'errors.max_length_error'
        | translate:{
          fieldName: fieldLabel,
          value: elementControl.errors?.maxlength?.requiredLength
      } }}
    </div>
  </ng-template>

  <ng-template #maxNumber>
    <div *ngIf="elementControl.errors?.max; else minNumber">
      {{'errors.max_error' | translate: {fieldName: fieldLabel, value: elementControl.errors?.max.max} }}
    </div>
  </ng-template>

  <ng-template #minNumber>
    <div *ngIf="elementControl.errors?.min; else pattern">
      {{'errors.min_error' | translate: {fieldName: fieldLabel, value: elementControl.errors?.min.min} }}
    </div>
  </ng-template>

  <ng-template #pattern>
    <div *ngIf="elementControl.errors?.pattern">
      {{'errors.pattern' | translate: {fieldName: fieldLabel} }}
    </div>
  </ng-template>
</div>
