import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CdkTableDataSourceInput} from '@angular/cdk/table';
import {DataTableActionsColumn, DataTableColumn} from './table.interface';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'ubiquity-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input()
  data: Array<T> = [];

  @Input()
  columns: DataTableColumn<T>[] = [];

  @Input()
  actionsColumn?: DataTableActionsColumn<T>;

  dataSource: MatTableDataSource<T>;
  displayedColumns: string[] = [];

  constructor() {
    this.dataSource = new MatTableDataSource<T>(this.data);
  }

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(col => col.id! as string);
    if (this.actionsColumn) {
      this.displayedColumns = this.displayedColumns.concat(this.actionsColumn.id);
    }
  }

  ngAfterViewInit() {
    this.initTable(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.initTable(changes.data.currentValue);
    }
  }

  initTable(tableData: Array<T>) {
    this.dataSource = new MatTableDataSource<T>(tableData);
    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.sort.active = 'date';
      this.sort.direction = 'desc';
    });
  }

  applyFilter(text: string, persistPage: boolean = false) {

    this.dataSource.filter = text.trim().toLowerCase();

    if (this.dataSource.paginator && !persistPage) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterPredicate: ((data: T, filter: string) => boolean) = (data: T, filter: string): boolean => {
    const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {

      let dataToMatch: string | undefined = '';

      if (this.displayedColumns.includes(key)) {
        dataToMatch = (data as {[key: string]: any})[key];

        if (this.columns.find((col) => col.id === key)?.customFilterContent) {
          dataToMatch = this.columns.find((col) => col.id === key)?.customFilterContent!((data as {[key: string]: any})[key]);
        }
      }

      return currentTerm + dataToMatch + '◬';
    }, '').toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) != -1;
  }
}
