import {AuthStateModel} from './auth.model';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  FetchCurrentUser,
  ForceChangePassword,
  ForgotPassword,
  ForgotPasswordConfirm,
  Login,
  Logout,
  RefreshTokens, SetEmail,
  SetPharmacyCode,
  SilentLogin
} from './auth.actions';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {AmplifyAuthService} from '../../../core/services/amplify-auth.service';
import {GetAppConfig} from '../../../store/app.action';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: '',
    pharmacyCode: '',
    pharmacyName: '',
    user: null,
    email: '',
    password: '',
    error: '',
    loading: false,
    rememberMe: false
  }
})
@Injectable()
export class AuthState {
  constructor(private router: Router,
              private zone: NgZone,
              private store: Store,
              private auth: AmplifyAuthService) {
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static email(state: AuthStateModel): string {
    return state.email;
  }

  @Selector()
  static user(state: AuthStateModel): string {
    return state.user;
  }

  @Selector()
  static logged(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static error(state: AuthStateModel): string {
    return state.error;
  }

  @Selector()
  static loading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static rememberMe(state: AuthStateModel): boolean {
    return state.rememberMe;
  }

  @Selector()
  static pharmacyCode(state: AuthStateModel): string {
    return state.pharmacyCode;
  }

  @Selector()
  static pharmacyName(state: AuthStateModel): string {
    return state.pharmacyName;
  }

  // ngxsOnInit(ctx: StateContext<AuthStateModel>): Observable<void> {
  //   return ctx.dispatch(new FetchCurrentUser());
  // }

  @Action(Login)
  login({patchState}: StateContext<AuthStateModel>, {email, password, rememberMe}: Login): Promise<any> {
    patchState({loading: true});

    return this.auth.signIn(email, password)
      .then(cognitoUser => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          patchState({email, password, rememberMe, error: ''});
          this.zone.run(() => this.router.navigate(['force-change-password']));
        } else {
          const {signInUserSession: {idToken}} = cognitoUser;
          patchState({
            email,
            user: idToken.payload,
            token: idToken.jwtToken,
            error: '',
            rememberMe
          });
          this.zone.run(() => {
            this.store.dispatch(new GetAppConfig());
            this.router.navigate(['home']);
          });
        }
      })
      .catch(e => patchState({error: e.code}))
      .finally(() => patchState({loading: false}));
  }

  @Action(ForceChangePassword)
  forceChangePassword({patchState, getState}: StateContext<AuthStateModel>,
                      {newPassword}: ForceChangePassword): Promise<any> {
    const {email, password} = getState();
    patchState({loading: true});

    return this.auth.signIn(email, password)
      .then(cognitoUser => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return this.auth.completeNewPassword(cognitoUser, newPassword)
            .then(user => {
              const {signInUserSession: {idToken}} = user;
              patchState({
                user: idToken.payload,
                token: idToken.jwtToken,
                email,
                password: '',
                error: ''
              });
              this.zone.run(() => this.router.navigate(['home']));
            });
        }
      })
      .catch(e => patchState({error: e.code}))
      .finally(() => patchState({loading: false}));
  }

  @Action(ForgotPassword)
  forgotPassword({patchState, getState}: StateContext<AuthStateModel>,
                 {email}: ForgotPassword): Promise<any> {
    patchState({loading: true});

    return this.auth.forgotPassword(email)
      .then(() => {
        patchState({email});
        this.zone.run(() => this.router.navigate(['forgot-password-confirm']));
      })
      .catch(e => patchState({error: e.code}))
      .finally(() => patchState({loading: false}));
  }

  @Action(ForgotPasswordConfirm)
  forgotPasswordConfirm({patchState, getState}: StateContext<AuthStateModel>,
                        {code, newPassword}: ForgotPasswordConfirm): Promise<any> {
    const {email} = getState();
    patchState({loading: true});

    return this.auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => this.zone.run(() => {
        patchState({email: '', error: ''});
        this.zone.run(() => this.router.navigate(['']));
      }))
      .catch(e => patchState({error: e.code}))
      .finally(() => patchState({loading: false}));
  }

  @Action(FetchCurrentUser)
  fetchCurrentUser({patchState}: StateContext<AuthStateModel>): Promise<any> {
    return this.auth.currentAuthenticatedUser()
      .then(user => {
        const {signInUserSession: {idToken}} = user;
        patchState({
          user: idToken.payload,
          token: idToken.jwtToken,
          error: ''
        });
      })
      .catch(() => {
        this.zone.run(() => this.router.navigate(['']));
      });
  }

  @Action(Logout)
  logout({patchState}: StateContext<AuthStateModel>): Promise<any> {
    return this.auth.signOut()
      .then(() => {
        patchState({
          token: '',
          user: null
        });
        window.localStorage.clear();
        this.zone.run(() => this.router.navigate(['']));
      });
  }

  @Action(RefreshTokens)
  refreshTokens(context: StateContext<AuthStateModel>): Promise<any> {
    const {patchState, dispatch} = context;

    return this.auth.currentSession()
      .then((authState: any) => {
        const {idToken} = authState;
        return patchState({token: idToken.jwtToken});
      })
      .catch(() => {
        dispatch(new Logout());
      });
  }

  @Action(SilentLogin)
  silentLogin({patchState}: StateContext<AuthStateModel>, {token}: SilentLogin): any {
    this.store.dispatch(new GetAppConfig());
    return patchState({token});
  }

  @Action(SetPharmacyCode)
  setPharmacyCode({patchState}: StateContext<AuthStateModel>, {pharmacyCode, pharmacyName}: SetPharmacyCode): any {
    return patchState({
      pharmacyCode,
      pharmacyName
    });
  }

  @Action(SetEmail)
  setEmail({patchState}: StateContext<AuthStateModel>, {email}: SetEmail): any {
    return patchState({email});
  }
}
