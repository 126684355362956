import {StoreModel} from '../../features/store-locator/models/store.model';
import {STORE_TYPES} from '../../features/store-locator/enums/store-locator.enums';

export const notEmpty = (obj: any) => Object.keys(obj).length;

export const getPharmacyCode = (token: string) => JSON.parse(atob(token.split('.')[1]))['custom:pharmacy_code'];
export const getTokenProperty = (token: string, property: string) => JSON.parse(atob(token.split('.')[1]))[property];

export const CSVToJSON = (data: any, delimiter = ',') => {
  const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map((v: any) => {
      const values = v.split(delimiter);
      return titles.reduce(
        (obj: any, title: any, index: any) => ((obj[title] = values[index]), obj),
        {}
      );
    });
};

export const convertToBool = (val: any): boolean => {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();

    return val === 'true' || val === '';
  }

  return !!val;
}

export const getMarkerFromStore = (store: StoreModel) => {
  const icons = {
    red_filled: '/assets/images/red-a.svg',
    red_line: '/assets/images/red-line-a.svg',
    black_filled: '/assets/images/black-a.svg',
    black_line: '/assets/images/black-line-a.svg'
  };

  const externalIcons = {
    red_filled: 'https://assets.amplifon.com/content/dam/amplifon-digital-assets/commons-amplifon/map-pin-baa-S.png',
    red_line: 'https://assets.amplifon.com/content/dam/amplifon-digital-assets/commons-amplifon/map-pin-baa-P.png',
    black_filled: '/assets/images/black-a.svg',
    black_line: '/assets/images/black-line-a.svg'
  };

  const defaultIcon = store.type === STORE_TYPES.S ? icons.red_filled : store.type === STORE_TYPES.P ? icons.red_line : '';
  const selectedIcon = store.type === STORE_TYPES.S ? icons.black_filled : store.type === STORE_TYPES.P ? icons.black_line : '';

  const extDefaultIcon = store.type === STORE_TYPES.S ? externalIcons.red_filled : store.type === STORE_TYPES.P ? externalIcons.red_line : '';
  const extSelectedIcon = store.type === STORE_TYPES.S ? externalIcons.black_filled : store.type === STORE_TYPES.P ? externalIcons.black_line : '';

  return {
    position: {
      lat: store.latitude,
      lng: store.longitude
    },
    defaultIcon,
    selectedIcon,
    externalIcons: {
      defaultIcon: extDefaultIcon,
      selectedIcon: extSelectedIcon
    },
    id: store.shopNumber
  }
}
