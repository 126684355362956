import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader.service';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AuthState} from 'src/app/features/auth/store/auth.state';

@Component({
  selector: 'ubiquity-loader',
  template: `
    <div
      *ngIf="visible || (loading$ | async)"
      class="loader-overlay">
      <div class="loader"></div>
    </div>
  `,
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

  visible: boolean = false;
  @Select(AuthState.loading) loading$!: Observable<boolean>;

  constructor(public loaderService: LoaderService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loaderService.loaderSubject.subscribe(loading => {
      this.visible = loading;
      this.cd.detectChanges();
    });
  }
}
