import {Component, forwardRef, HostListener, Inject, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {_BasePicker} from './base-picker.component';
import {InputComponent} from '../input/input.component';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

@Component({
  selector: 'ubiquity-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent<D> extends _BasePicker<D, D> implements ControlValueAccessor {

  @ViewChild('picker') picker!: MatDatepicker<D>;
  @HostListener('click', ['$event.target'])
  hostClick() {
    if (this.disableInput) {
      this.picker.open();
    }
  }
  @Input() readonly: boolean = false;

  @Input() disableInput: boolean = false;

  @ViewChild('pickerInput', { static: true }) pickerInput!: InputComponent;

  date: any;

  private onTouched: Function = () => {};
  private onChanged: Function = () => {};

  public disabled = false;

  public constructor(
    protected adapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) protected dateFormats: MatDateFormats
  ) {
    super(adapter, dateFormats);
  }

  writeValue(obj: any): void {
    if (!!obj) {
      this.date = obj;
    } else {
      this.date = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  selectDate(date: D | null) {
    let newDate = null;

    if (date) {
      newDate = date;
    }

    this.onTouched();
    this.date = date;

    this.onChanged(newDate);
    this.dateChange.emit(newDate as any);
  }
}
