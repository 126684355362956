import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/features/auth/store/auth.state';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(protected $http: HttpClient, private store: Store) {
  }

  call<T>(method = 'get', path: any[] = [], body = {}, params = {}, additionalHeaders = {}): Observable<T | any> {
    const {api: {url, key}} = environment;
    const token = this.store.selectSnapshot(AuthState.token);

    return this.$http.request<T>(
      method,
      `${url}/${path.join('/')}`,
      {
        body,
        params,
        headers: new HttpHeaders({
          'x-api-key': key,
          // 'client_id': clientId,
          // 'X-OnAir-ClientAppKey': cOnAirClientAppKey,
          'Authorization': token,
          // 'channel': channel,
          ...additionalHeaders
        })
      });
  }
}
