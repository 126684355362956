<div class="header">
  <div routerLink="" class="logo"></div>
  <div class="menu" *ngIf="(logged$ | async) && (pharmacyName$ | async)">
    <ul class="navigation-menu">
      <li routerLink="book-appointment">{{'shared.menu.book-appointment' | translate}}</li>
      <li routerLink="store-locator">{{'shared.menu.store-locator' | translate}}</li>
      <li routerLink="screening">{{'shared.menu.screening' | translate}}</li>
    </ul>
    <div class="user-menu">
      <div class="user-data">
        <span>{{email$ | async}}</span>
        <h4 (click)="switchPharmacy()">{{pharmacyName$ | async}}</h4>
      </div>
      <div class="logout">
        <span (click)="logout()" class="cta-secondary">{{'shared.menu.logout' | translate}}</span>
      </div>
    </div>
  </div>
</div>
