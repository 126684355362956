import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthState} from 'src/app/features/auth/store/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public store: Store, private router: Router) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const logged = this.store.selectSnapshot(AuthState.logged);

    if (logged) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}
