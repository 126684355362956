import {ChangeDetectorRef, Injectable} from '@angular/core';
import {BehaviorSubject, interval} from 'rxjs';
import {debounce} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loaderSubject = new BehaviorSubject(false);
  visible = this.loaderSubject.asObservable().pipe(debounce(() => interval(200)));

  show(): void {
    return this.loaderSubject.next(true);
  }

  hide(): void {
    return this.loaderSubject.next(false);
  }
}
