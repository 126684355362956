import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { convertToBool} from '../../utils/utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ubiquityButton], a[ubiquityButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  private _disabled: boolean = false;

  @HostBinding('class.disabled') public classDisabled = false;

  @Input()
  public set disabled(val: boolean) {
    this._disabled = convertToBool(val);
    this.classDisabled = convertToBool(val);
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  private _selected: boolean = false;

  @HostBinding('class.selected') public classSelected = false;

  @Input()
  public set selected(val: boolean) {
    this._selected = convertToBool(val);
    this.classSelected = convertToBool(val);
  }

  public get selected(): boolean {
    return this._selected;
  }

  @HostBinding('class.cta-primary') public classPrimary = false;

  @Input()
  public set primary(val: boolean | string) {
    this.classPrimary = convertToBool(val);
  }

  @HostBinding('class.cta-secondary') public classSecondary = false;

  @Input()
  public set secondary(val: boolean | string) {
    this.classSecondary = convertToBool(val);
  }

  @HostBinding('class.cta-primary-on-dark') public classPrimaryOnDark = false;

  @Input()
  public set primaryOnDark(val: boolean | string) {
    this.classPrimaryOnDark = convertToBool(val);
  }

  @HostBinding('class.cta-secondary-on-dark') public classSecondaryOnDark = false;

  @Input()
  public set secondaryOnDark(val: boolean | string) {
    this.classSecondaryOnDark = convertToBool(val);
  }

  @HostListener('click', ['$event'])
  public handleClick(event: Event) {
    if (this._disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }
}
