import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'ubiquity-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() id!: string | number;
  @Input() name!: string;
  @Input() label!: string;
  @Input() form!: FormGroup;
  @Input() disableErrors: boolean = false;

  checkboxFC!: FormControl;

  constructor() { }

  ngOnInit(): void {
    if (this.form) {
      if (this.name) {
        this.checkboxFC = this.form.get(this.name) as FormControl;
      } else {
        this.name = 'checkbox';
        this.checkboxFC = new FormControl();
        this.form.setControl(this.name, this.checkboxFC);
      }
    } else {
      this.name = this.name || 'checkbox';
      this.checkboxFC = new FormControl();

      this.form = new FormGroup({
        [this.name]: this.checkboxFC
      });
    }
  }
}
