import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, filter, finalize, tap} from 'rxjs/operators';
import {ApiErrorService} from './api-error.service';
import {Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {LoaderService} from './loader.service';

@Injectable({
  providedIn: 'root',
  deps: [LoaderService, ApiErrorService, Store, Router]
})
export class HttpInterceptorService implements HttpInterceptor {
  private inFlightRequests = 0;

  constructor(
    private loaderService: LoaderService,
    private errorService: ApiErrorService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();

    return next.handle(req)
      .pipe(
        filter(req => req instanceof HttpResponse),
        tap(() => this.inFlightRequests++),
        catchError((error) => {
          this.errorService.setError(error.status, error.error.error);
          throw error;
        }),
        finalize(() => {
          this.decreaseRequests();
        })
      );
  }

  private decreaseRequests(): void {
    this.inFlightRequests--;
    if (this.inFlightRequests <= 0) {
      this.inFlightRequests = 0;
      this.loaderService.hide();
    }
  }
}
