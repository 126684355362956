import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AppStateConfigModel, AppStateConfigPharmaciesModel, AppStateModel} from './app.model';
import {Injectable} from '@angular/core';
import {GetAppConfig} from './app.action';
import {Observable} from 'rxjs';
import {ApiService} from '../core/services/api.service';
import {map} from 'rxjs/operators';

@State<AppStateModel>({
  name: 'appState',
  defaults: {
    config: {
      updatableFrom: '',
      pharmacies: []
    }
  }
})
@Injectable()
export class AppState {

  @Selector()
  static pharmacies(state: AppStateModel): AppStateConfigPharmaciesModel[] {
    return state.config.pharmacies;
  }

  @Selector()
  static updatableFrom(state: AppStateModel): string {
    return state.config.updatableFrom;
  }
  constructor(private api: ApiService) {
  }

  @Action(GetAppConfig)
  getAppConfig({patchState}: StateContext<AppStateModel>): Observable<AppStateModel> {
    return this.api.call<AppStateConfigModel>('GET', ['ubiquity', 'configuration'])
      .pipe(
        map((res: AppStateConfigModel) => patchState({
          config: res
        }))
      );
  }
}
