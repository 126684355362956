import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MapGeocoder} from '@angular/google-maps';
import {take, takeUntil} from 'rxjs/operators';
import {DestroyService} from '../../services/destroy.service';
import {LoaderService} from '../../services/loader.service';
import {ApiErrorService} from '../../services/api-error.service';

export interface PositionPayload {
  latitude: number;
  longitude: number;
  address: string;
  location: google.maps.LatLng
}

@Component({
  selector: 'ubiquity-search-location-input',
  templateUrl: './search-location-input.component.html',
  styleUrls: ['./search-location-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService]
})
export class SearchLocationInputComponent implements OnInit, AfterViewInit {
  @ViewChild('ubiquityInput', {static: true}) ubiquityInputEl!: any;

  @Input() locationSuggestions: any[] = [];
  @Input() name!: string;
  @Input() form!: FormGroup;
  @Input() searchInputFC!: FormControl;
  @Input() id!: string;
  @Output() search = new EventEmitter();

  matchedPlaces: boolean = false;
  autocomplete!: google.maps.places.Autocomplete;
  placeholder = 'book_appointment.insert_address';
  bodyEl!: any;

  constructor(
    private elRef: ElementRef,
    private geocoder: MapGeocoder,
    private destroy$: DestroyService,
    private loader: LoaderService,
    private apiError: ApiErrorService) {
  }

  ngOnInit(): void {
    if (!this.form) {
      this.form = new FormGroup({});
    }

    if (!this.name) {
      this.name = 'searchLocation';
    }

    if (!this.searchInputFC) {
      if (!this.form.get(this.name)) {
        this.searchInputFC = new FormControl();
        this.form.setControl(this.name, this.searchInputFC);
      } else {
        this.searchInputFC = this.form.get(this.name) as FormControl;
      }
    } else {
      if (!this.form.get(this.name)) {
        this.form.setControl(this.name, this.searchInputFC)
      }
    }
  }

  ngAfterViewInit() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.ubiquityInputEl.inputEl.nativeElement
    );

    this.autocomplete.setComponentRestrictions({country: ['IT']});

    this.autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult | undefined = this.autocomplete.getPlace();

      if (place.place_id) {
        const latitude = place!.geometry!.location!.lat();
        const longitude = place!.geometry!.location!.lng();
        const location = new google.maps.LatLng(latitude, longitude);
        this.search.emit({
          latitude,
          longitude,
          address: place.formatted_address,
          location
        });
      }
    });

    this.bodyEl = this.elRef.nativeElement.closest('body');

    this.searchInputFC
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this._updateMatchPlaces());
  }

  onFocusIn() {
    this._updateMatchPlaces();
  }

  onFocusOut() {
    this._updateMatchPlaces();
  }

  onLocalizeMe() {
    this.loader.show();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {coords: {latitude, longitude}} = position;
        const location = new google.maps.LatLng(latitude, longitude);
        this.geocoder.geocode({location})
          .pipe(take(1))
          .subscribe(({results}) => {
            this.loader.hide();
            this.search.emit({
              latitude,
              longitude,
              address: results![0].formatted_address,
            });
          });

      },
      (err) => {
        this.loader.hide();

        if (err instanceof GeolocationPositionError && err.code === 1) {
          this.apiError.setError(`GeolocationPositionError_${err.code}`)
        }
      }
    )
  }

  onSearch() {
    const query = this.searchInputFC.value;
    this.geocoder.geocode({address: query})
      .pipe(take(1))
      .subscribe(({results}) => {
        if (results[0]) {
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();
          const location = new google.maps.LatLng(latitude, longitude);
          this.search.emit({
            latitude,
            longitude,
            address: results![0].formatted_address,
            location
          });
        }
      });
  }

  private _updateMatchPlaces() {
    setTimeout(() => {
      const pacContainer = this.bodyEl.querySelector('.pac-container');
      const styles = getComputedStyle(pacContainer);

      this.matchedPlaces = styles.getPropertyValue('display') === 'block';
    }, 250);
  }
}
