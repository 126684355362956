<div class="search-location-input-cnt"
     [ngClass]="{
      '--opened-suggestions': matchedPlaces
     }"
>

  <ubiquity-input
      #ubiquityInput
      type="text"
      [name]="name"
      [form]="form"
      [placeholder]="placeholder | translate"
      (focusin)="onFocusIn()"
      (focusout)="onFocusOut()"
      (enter)="onSearch()"
      [disableErrors]="true"
  ></ubiquity-input>

  <div class="input-layer-cnt">
    <div class="text-cnt">
      <a (click)="onLocalizeMe()">
        {{'shared.search_input.localize_me' | translate}}
      </a>
    </div>

    <div class="icon-cnt">
      <button type="button" class="cta-primary" (click)="onSearch()">
        <i class="ecc-icon-search"></i>
      </button>
    </div>
  </div>

<!--  <div class="suggestions-cnt"-->
<!--       *ngIf="searchInputFC?.dirty-->
<!--          && locationSuggestions-->
<!--          && locationSuggestions.length > 0"-->
<!--  >-->
<!--    <div class="suggestion-cnt"-->
<!--         (click)="onSelectLocation(suggestion)"-->
<!--         *ngFor="let suggestion of locationSuggestions"-->
<!--    >-->
<!--      <div class="title">-->
<!--        &lt;!&ndash; Location Name &ndash;&gt;-->
<!--        {{suggestion[0]}}-->
<!--      </div>-->

<!--      <div class="icon-cnt">-->
<!--        <i class="ecc-icon-chevron-right"></i>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
