import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {FetchStores, ResetStores, SelectMarker, SelectStore} from './store-locator.actions';
import {StoreLocatorModel} from './store-locator.model';
import {STORE_TYPES} from '../enums/store-locator.enums';
import {StoreModel} from '../models/store.model';
import {ApiService} from '../../../core/services/api.service';
import {map, tap} from 'rxjs/operators';
import {getMarkerFromStore} from '../../../core/utils/utils';


@State<StoreLocatorModel>({
  name: 'storeLocator',
  defaults: {
    stores: [],
    storeMapMarkers: [],
    selectedMarker: null,
    selectedStore: null
  }
})
@Injectable()
export class StoreLocatorState {
  constructor(private _api: ApiService) {
  }

  @Selector()
  static stores(state: StoreLocatorModel): StoreModel[] {
    return state.stores;
  }

  @Selector()
  static storeMapMarkers(state: StoreLocatorModel): any[] {
    return state.stores.map(getMarkerFromStore);
  }

  @Selector()
  static selectedMarker(state: StoreLocatorModel): any {
    return state.selectedMarker;
  }

  @Selector()
  static selectedStore(state: StoreLocatorModel): any {
    return state.selectedStore;
  }

  @Action(FetchStores)
  fetchStores({patchState}: StateContext<StoreLocatorModel>, {location}: FetchStores): any {

    return this._api.call('post', ['ubiquity', 'store-locator', 'v3', 'getStores'], location)
      .pipe(
        map(stores => stores.map((store: any) => {
          return {
            ...store,
            id: store.shopNumber
          }
        })),
        tap(stores => patchState({stores}))
      )
  }

  @Action(SelectMarker)
  selectMarker({patchState}: StateContext<StoreLocatorModel>, {marker}: SelectMarker): any {
    return patchState({selectedMarker: marker});
  }

  @Action(SelectStore)
  selectStore({patchState}: StateContext<StoreLocatorModel>, {store}: SelectStore): any {
    return patchState({selectedStore: store});
  }

  @Action(ResetStores)
  resetStores({patchState}: StateContext<StoreLocatorModel>): any {
    return patchState({stores: []});
  }
}
