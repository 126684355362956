import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

// import Swiper core and required modules
import SwiperCore, {
  A11y, Autoplay, EffectFade, Navigation, Pagination, Scrollbar, Virtual, Grid
} from 'swiper';

import {SwiperComponent} from "swiper/angular";

// Swiper modules
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
  EffectFade,
  Grid
]);
@Component({
  selector: 'ubiquity-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent {
  @Input() swipeSpeed: number = 250;

  @Input() config: any = {
    slidesPerView: 4,
    spaceBetween: 10,
    grid: {
      rows: 1
    }
  };

  @Input() sliderItemId: string = 'sliderItem';
  @Input() data: any[] = [];

  @ViewChild('swiper', { static: false }) swiper!: SwiperComponent;
  @ContentChild('sliderItem') sliderItem!: TemplateRef<any>;
  @ContentChild('startSlide') startSlide!: TemplateRef<any>;
  @ContentChild('endSlide') endSlide!: TemplateRef<any>;

  prevDisabled: boolean = false;
  nextDisabled: boolean = false;

  constructor(private cdr: ChangeDetectorRef) { }

  onSwiper(ev: any) {
    // init prevDisabled, nextDisabled
    this._checkChevronsAvailability();
  }

  slideNext() {
    if (!this.nextDisabled) {
      this.swiper.swiperRef.slideNext(this.swipeSpeed);

      this._checkChevronsAvailability();
    }
  }

  slidePrev() {
    if (!this.prevDisabled) {
      this.swiper.swiperRef.slidePrev(this.swipeSpeed);
      this._checkChevronsAvailability();
    }
  }

  onTouchMove() {
    this._checkChevronsAvailability(true);
  }

  private _checkChevronsAvailability (detectChanges?: boolean) {
    this.prevDisabled = this.swiper.swiperRef.isBeginning;
    this.nextDisabled = this.swiper.swiperRef.isEnd;

    if (detectChanges) {
      this.cdr.detectChanges();
    }
  }
}
