<div class="material-overrides">
  <div class="ubiquity-table">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" aria-label="Screening report table">

      <ng-container *ngFor="let column of columns" matColumnDef="{{ column.id }}">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{column.displayName}} </th>
        <td mat-cell *matCellDef="let element"> {{column.content(element)}} </td>
      </ng-container>

      <ng-container *ngIf="actionsColumn" matColumnDef="{{ actionsColumn.id }}">
        <th mat-header-cell *matHeaderCellDef> {{actionsColumn.displayName}} </th>
        <td mat-cell *matCellDef="let element">
          <div class="actions-wrapper">
            <ng-container *ngIf="!!actionsColumn.actions || true">
              <ng-container *ngFor="let action of actionsColumn.actions">
                <button
                  ubiquityButton
                  primary
                  [disabled]="action.disabled(element)"
                  (click)="!action.disabled(element) ? action.callback(element) : ''"
                  [style.display]="action.visible(element) ? '' : 'none'"
                >
                  <i [ngClass]="action.iconClass"></i>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'shared.table.filters-no-results' | translate}}</td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10"></mat-paginator>
  </div>
</div>
