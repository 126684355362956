import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {Logout, SetEmail, SetPharmacyCode, SilentLogin} from '../store/auth.actions';
import {getPharmacyCode, getTokenProperty} from '../../../core/utils/utils';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(private store: Store, private router: Router) {
  }

  login() {
    const {sso: {login}} = environment;
    window.location.href = login;
  }

  logout() {
    const {sso: {logout}} = environment;
    this.store.dispatch(new Logout())
      .pipe(
        take(1)
      )
      .subscribe(() => {
        window.location.href = logout;
      });
  }

  setToken(fragment: string) {
    if (fragment) {
      const {id_token} = this.getHashParams(fragment);

      if (id_token) {
        const pharmacyCode = getPharmacyCode(id_token);
        const email = getTokenProperty(id_token, 'email');

        this.store.dispatch(new SilentLogin(id_token))
          .subscribe(() => {

            if (email) {
              this.store.dispatch(new SetEmail(email));
            }

            if (!!pharmacyCode) {
              this.store.dispatch(new SetPharmacyCode(pharmacyCode));
              this.router.navigate(['home']);
            } else {
              this.router.navigate(['pharmacies']);
            }
          });
      }
    }
  }

  private getHashParams(hash: string): any {
    const params = hash.split('&');
    return params.reduce((acc, curr) => {
      const [key, val] = curr.split('=');
      return ((acc as any)[key] = val) && acc;
    }, {});
  }
}
