import {Component, forwardRef} from '@angular/core';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateRange} from '@angular/material/datepicker';
import {_BasePicker} from './base-picker.component';

export interface DateRangePickerInterface {
  start: string,
  end: string
}
@Component({
  selector: 'ubiquity-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ]
})
export class DateRangePickerComponent<D> extends _BasePicker<D, DateRange<D>>{

  private _lastEvent = new DateRange<D>(null, null);

  public dateRangeForm: FormGroup = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  public onTouched: () => void = () => {};

  writeValue(val: DateRangePickerInterface): void {
    val && this.dateRangeForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.dateRangeForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.dateRangeForm.disable() : this.dateRangeForm.enable();
  }

  onInput(currentValue: string, targetMaterialInput: HTMLInputElement) {
    targetMaterialInput.value = currentValue;
    targetMaterialInput.dispatchEvent(new Event('input'));
  }

  onBlur(targetMaterialInput: HTMLInputElement) {
    targetMaterialInput.dispatchEvent(new Event('change')); // Material inputs trigger the dateChange event after a native change event
  }

  onDateChange(ev: DateRange<D> | null) {
    if (ev && this.shouldEmit(ev)) {
      this._lastEvent = ev;
      this.dateChange.emit(ev);
    }
  }

  private shouldEmit(event: DateRange<D>) {
    return this._lastEvent.start !== event.start || this._lastEvent.end !== event.end;
  }

}
