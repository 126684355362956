import {FetchStoresRequestModel} from '../models/store.model';

export class FetchStores {
    static readonly type = '[StoreLocator] FetchStores';

    constructor(public location: FetchStoresRequestModel) {}
}

export class ResetStores {
  static readonly type = '[StoreLocator] ResetStores';

  constructor() {}
}

export class SelectMarker {
  static readonly type = '[StoreLocator] Select Marker';

  constructor(public marker: any) {}
}

export class SelectStore {
  static readonly type = '[StoreLocator] Select Store';

  constructor(public store: any) {}
}
