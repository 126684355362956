<div class="swiper-cnt">
  <swiper
    #swiper
    class="u-swiper"
    [ngClass]="{'--with-grid': config.grid?.rows}"
    [config]="config"
    (swiper)="onSwiper($event)"
    (touchMove)="onTouchMove()"
  >
    <ng-template swiperSlide *ngIf="startSlide">
      <ng-container *ngTemplateOutlet="startSlide"></ng-container>
    </ng-template>

    <ng-template
      *ngFor="let item of data;
      let i = index"
      swiperSlide
    >
      <ng-container
        *ngTemplateOutlet="sliderItem; context: { $implicit: item }"
      >
      </ng-container>
    </ng-template>

    <ng-template swiperSlide *ngIf="endSlide">
      <ng-container *ngTemplateOutlet="endSlide"></ng-container>
    </ng-template>
  </swiper>

  <div class="prev-cnt"
       [ngClass]="{'--disabled': prevDisabled}"
       (click)="slidePrev()"
  >

    <i class="ecc-icon-chevron-right"></i>
  </div>

  <div class="next-cnt"
       [ngClass]="{'--disabled': nextDisabled}"
       (click)="slideNext()">

    <i class="ecc-icon-chevron-right"></i>
  </div>
</div>
