import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {AuthState} from '../../../features/auth/store/auth.state';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {SsoService} from '../../../features/auth/services/sso.service';

@Component({
  selector: 'ubiquity-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Select(AuthState.logged) logged$!: Observable<boolean>;
  @Select(AuthState.pharmacyName) pharmacyName$!: Observable<string>;
  @Select(AuthState.email) email$!: Observable<string>;

  constructor(private router: Router, private sso: SsoService) {
  }

  switchPharmacy() {
    this.router.navigate(['pharmacies']);
  }
  logout() {
    this.sso.logout();
  }
}
