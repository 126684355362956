import {Directive, EventEmitter, Inject, Input, Output} from '@angular/core';
import {DateFilterFn, MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';

@Directive()
export abstract class _BasePicker<TDate, TEvent> {
  @Input() public placeholder?: string = '';
  @Input() public minDate: TDate | null = null;
  @Input() public maxDate: TDate | null = null;
  @Input() public dateFilter?: DateFilterFn<TDate | null>;

  @Output() public dateChange = new EventEmitter<TEvent | null>();

  public constructor(
    protected adapter: DateAdapter<TDate>,
    @Inject(MAT_DATE_FORMATS) protected dateFormats: MatDateFormats
  ) {}

  public getDateClass: MatCalendarCellClassFunction<TDate> = (cellDate, _) => {
    const classes = ['date-cell', 'description'];
    if (this.adapter.sameDate(cellDate, this.adapter.today())) classes.push('date-today');
    return classes;
  };

  filterDates = (d: TDate | null): boolean => {
    return this.dateFilter ? this.dateFilter(d as TDate) : true;
  };
}
