import {Component} from '@angular/core';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'ubiquity-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private dateAdapter: DateAdapter<any>) {
    dateAdapter.setLocale('it-IT');
  }
}
