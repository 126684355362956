import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {LoaderService} from './loader.service';

export interface Error {
  errorCode: number;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {
  errorSubject$: Subject<any> = new Subject();
  error$ = this.errorSubject$.asObservable();

  constructor(private translate: TranslateService, private loader: LoaderService) {
  }

  setError(errorCode: number | string, customMessageKey: string | null = null): Subscription {
    return this.translate.get('errors')
      .pipe(
        tap(() => this.loader.hide()),
        tap(messages => this.errorSubject$.next({
            code: errorCode,
            message: (customMessageKey && messages[customMessageKey]) ? messages[customMessageKey]: (messages[errorCode] || messages['GenericError'])
          })
        ),
        take(1)
      )
      .subscribe();
  }

  hideError() {
      this.errorSubject$.next(null);
  }
}
