<div [formGroup]="dateRangeForm" class="material-overrides">
  <mat-form-field appearance="outline" (click)="rangePicker.open()">
    <mat-date-range-input [rangePicker]="rangePicker" #rangeInput>
      <input
        readonly
        matStartDate
        formControlName="start"
        (dateChange)="onDateChange(rangeInput.value)"
        [placeholder]="'Start'">
      <input
        readonly
        matEndDate
        formControlName="end"
        (dateChange)="onDateChange(rangeInput.value)"
        [placeholder]="'end'">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangePicker">
      <mat-icon matDatepickerToggleIcon>
        <i class="ecc-icon-calendar"></i>
      </mat-icon>

    </mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker></mat-date-range-picker>
  </mat-form-field>
</div>
