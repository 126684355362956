import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LoaderComponent} from './components/loader/loader.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {InputComponent} from './components/input/input.component';
import {SearchLocationInputComponent} from './components/search-location-input/search-location-input.component';
import {SelectComponent} from './components/select/select.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {SliderComponent} from './components/slider/slider.component';
import {SwiperModule} from "swiper/angular";
import {FormElementErrorsComponent} from './components/form-element-errors/form-element-errors.component';
import {RouterModule} from '@angular/router';
import {GoogleMapsModule} from '@angular/google-maps';
import {ErrorComponent} from './components/error/error.component';
import {FilterByPipe} from './pipes/filter-by.pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {DateRangePickerComponent} from './components/date-picker/date-range-picker.component';
import {MatTableModule} from '@angular/material/table';
import {TableComponent} from './components/table/table.component';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ButtonComponent} from './components/button/button.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';


const MAT_MODULES = [
  MatDatepickerModule,
  MatInputModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatTooltipModule
]

const COMPONENTS = [
  ButtonComponent,
  LoaderComponent,
  HeaderComponent,
  FooterComponent,
  InputComponent,
  SearchLocationInputComponent,
  SelectComponent,
  CheckboxComponent,
  SliderComponent,
  FormElementErrorsComponent,
  ErrorComponent,
  DatePickerComponent,
  DateRangePickerComponent,
  TableComponent
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    FilterByPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SwiperModule,
    RouterModule,
    GoogleMapsModule,
    ...MAT_MODULES
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    GoogleMapsModule,
    FilterByPipe,
    ...COMPONENTS,
    ...MAT_MODULES
  ]
})
export class CoreModule {
}
