<input
  [ngClass]="{disabled: readonly}"
  #pickerInput
  [matDatepicker]="picker"
  (dateChange)="selectDate($event.value)"
  [min]="minDate"
  [max]="maxDate"
  [value]="date"
  [readonly]="readonly || disableInput"
  [attr.placeholder]="placeholder"
  [matDatepickerFilter]="filterDates"
/>
<button *ngIf="!disabled" [disabled]="readonly" type="button" ubiquityButton secondary (click)="picker.open()">
  <i class="ecc-icon-calendar"></i>
</button>
<mat-datepicker
  #picker
  [disabled]="readonly"
  panelClass="datepicker-content"
  [dateClass]="getDateClass"
></mat-datepicker>
