<div class="form-group input-cnt" [formGroup]="form">
  <div class="input"
       [ngClass]="{
      '--with-floating-label': floatingLabel,
      '--error': inputFC.invalid && (inputFC.dirty || inputFC.touched),
      '--valid': inputFC.valid && (inputFC.dirty || inputFC.touched),
      '--focused': isFocused || inputFC.value
     }">
    <label [for]="name" *ngIf="label" (click)="onFocusIn()">
      {{requiredLabel || label}}
    </label>

    <input
      #inputEl
      [id]="id"
      [name]="name"
      [formControlName]="name"
      [readOnly]="readonly"
      [type]="visible ? 'text' : type"
      [autocomplete]="disableAutocomplete ? 'off' : 'on'"
      [placeholder]="floatingLabel ? '' : placeholder"
      [class.error]="form.get(name)?.touched && form.get(name)?.invalid"
      (keyup.enter)="onEnter()"
      (blur)="onBlur()"
      (focusin)="onFocusIn()">

    <a
      *ngIf="type === 'password' && form?.get(name)?.value.length"
      (click)="visible = !visible">
      {{(visible ? 'shared.input-pwd.hide' : 'shared.input-pwd.show') | translate}}
    </a>
  </div>

  <ubiquity-form-element-errors
    *ngIf="!disableErrors"
    [elementControl]="inputFC"
    [fieldLabel]="label || placeholder"
  ></ubiquity-form-element-errors>
</div>
