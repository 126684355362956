import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'ubiquity-form-element-errors',
  templateUrl: './form-element-errors.component.html',
  styleUrls: ['./form-element-errors.component.scss']
})
export class FormElementErrorsComponent {
  @Input() elementControl!: FormControl;
  @Input() fieldLabel!: string;

  constructor() {}
}
