import {Component} from '@angular/core';

@Component({
  selector: 'ubiquity-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  contactsTooltipMessage: string = 'Contattaci all\'indirizzo mail portaleubiquity.support@amplifon.com';
}
