import {TranslateService} from '@ngx-translate/core';
import * as weekday from 'dayjs/plugin/weekday';
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/it'

export function configApplication(translate: TranslateService): () => Promise<boolean> {
  return () => new Promise(resolve => {
    translate.setDefaultLang('it');
    translate.use('it');

    dayjs.locale('it');
    dayjs.extend(weekday);
    dayjs.extend(customParseFormat);
    dayjs.extend(localeData);
    dayjs.extend(LocalizedFormat)
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isBetween);

    resolve(true);
  });
}
