export class Login {
  static readonly type = '[Auth] Login';

  constructor(public email: string, public password: string, public rememberMe: boolean) {
  }
}

export class SetPharmacyCode {
  static readonly type = '[Auth] SetPharmacyCode';

  constructor(public pharmacyCode: string, public pharmacyName: string = '') {
  }
}

export class SetEmail {
  static readonly type = '[Auth] SetEmail';

  constructor(public email: string) {
  }
}

export class ForceChangePassword {
  static readonly type = '[Auth] Force Change Password';

  constructor(public newPassword: string) {
  }
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password';

  constructor(public email: string) {
  }
}

export class ForgotPasswordConfirm {
  static readonly type = '[Auth] Forgot Password Comfirm';

  constructor(public code: string, public newPassword: string) {
  }
}

export class FetchCurrentUser {
  static readonly type = '[Auth] Fetch Current User';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class RefreshTokens {
  static readonly type = '[Auth] Refresh tokens';
}

// Unit tests only
export class SilentLogin {
  static readonly type = '[Auth] Silent Login';

  constructor(public token: string) {
  }
}
