export const environment = {
  production: true,
  api: {
    url: 'https://emea.api.amplifoninternal.com',
    key: '3E9MauM2AT2VGx1vyH6FL6yyaoU2fuYba4QwUbqk'
  },
  sso: {
    login: 'https://ubiquity-prod.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=AzureAD&response_type=token&client_id=1tp011b8jvjbhmaogorqjme52e&scope=openid&redirect_uri=https://portal.ubiquity.amplifon.com/',
    logout: 'https://ubiquity-prod.auth.eu-central-1.amazoncognito.com/logout?client_id=1tp011b8jvjbhmaogorqjme52e&logout_uri=https://portal.ubiquity.amplifon.com'
  }
};
