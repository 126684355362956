<div class="checkbox-cnt" [formGroup]="form">
  <div class="checkbox">
    <input
      type="checkbox"
      [id]="id"
      [name]="name"
      [formControlName]="name"
    />

    <label [for]="id">
      {{label}}
      <ng-content></ng-content>
    </label>
  </div>

  <ubiquity-form-element-errors
    *ngIf="!disableErrors"
    [fieldLabel]="label"
    [elementControl]="checkboxFC"
  ></ubiquity-form-element-errors>
</div>
