import {Component} from '@angular/core';
import {ApiErrorService} from '../../services/api-error.service';
import {Store} from '@ngxs/store';
import {Logout} from '../../../features/auth/store/auth.actions';

@Component({
  selector: 'ubiquity-error',
  template: `
    <div class="error" *ngIf="error.error$ | async as payload">
      <em class="ecc-icon-delete" (click)="close(payload.code)"></em>
      <p>{{payload.message}}</p>
    </div>
  `,
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(public error: ApiErrorService, private store: Store) {
  }

  close(code: number) {
    this.error.hideError();

    if (code === 401 || code === 403) {
      this.store.dispatch(new Logout())
    }
  }
}
