import {Injectable} from '@angular/core';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AmplifyAuthService {

  constructor() {
  }

  signIn(email: string, password: string): Promise<any> {
    return Auth.signIn(email, password);
  }

  completeNewPassword(cognitoUser: any, newPassword: string): Promise<any> {
    return Auth.completeNewPassword(cognitoUser, newPassword);
  }

  forgotPassword(email: string): Promise<any> {
    return Auth.forgotPassword(email);
  }

  forgotPasswordSubmit(email: string, code: string, newPassword: string): Promise<any> {
    return Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  currentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  changePassword(user: string, oldPassword: string, newPassword: string): Promise<any> {
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  signOut(): Promise<any> {
    return Auth.signOut();
  }

  currentSession(): Promise<any> {
    return Auth.currentSession();
  }
}
