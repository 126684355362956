import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'ubiquity-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnDestroy {
  @ViewChild('inputEl') public inputEl!: ElementRef;

  @Input() name!: string;
  @Input() form!: FormGroup;
  @Input() placeholder: string = '';
  @Input() type: 'text' | 'number' | 'email' | 'password' = 'text';
  @Input() label!: string;

  @Input()
  disableAutocomplete: boolean = false;
  @Input() floatingLabel: boolean = false;
  @Input() disableErrors: boolean = false;
  @Input() id!: string;

  @Input() readonly: boolean = false;

  @Output() enter = new EventEmitter();
  @Output() inputBlur = new EventEmitter();

  @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();

  destroy$: Subject<void> = new Subject<void>();
  public inputFC!: FormControl;
  public visible: boolean = false;
  public isFocused: boolean = false;

  requiredLabel!: string;

  constructor() {}

  ngOnInit() {
    if (this.form) {
      if (this.name) {
        this.inputFC = this.form.get(this.name) as FormControl;
      } else {
        this.name = 'input';
        this.inputFC = new FormControl();
        this.form.setControl(this.name, this.inputFC);
      }
    } else {
      this.name = this.name || 'input';
      this.inputFC = new FormControl();

      this.form = new FormGroup({
        [this.name]: this.inputFC
      });
    }

    this.inputFC.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((val: any) => {
        this.changeValue.emit(this.inputFC.value)
      })
    )
      .subscribe();

    if (this.inputFC.validator && this.inputFC.validator({} as AbstractControl)) {
      this.requiredLabel = this.label;
      const validator = this.inputFC.validator({} as AbstractControl);

      if (validator?.required) {
        this.requiredLabel += '*'
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBlur() {
    this.inputBlur.emit();
    this.isFocused = false;
  }

  onFocusIn() {
    this.isFocused = true;
  }

  onEnter() {
    this.enter.emit();
  }
}
